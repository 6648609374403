import React from "react";

// Bootstrap components
import {
  Container,
  Nav,
  Navbar,
  Row,
  Col,
} from "react-bootstrap";

// Material UI components
import { useTheme } from '@mui/material';

// Material ui components
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RoomIcon from "@mui/icons-material/Room";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";

import { Link } from "react-router-dom";

//  Types
import Contact from "../global/ContactUs";

// Custom components
import { StyledIcon, StyledButton } from '../icons/StyledClicks';
import { StyledNavItem } from '../icons/StyledNavItem';


interface NavBarProps extends Contact {
  hasTopBar: boolean;
};


const CustomNavBar: React.FC<NavBarProps> = ({
  brandName,
  address,
  openingHours,
  email,
  phone,
  hasTopBar
}) => {

  const theme = useTheme();

  const content = (
    <>
      <Container
        className={hasTopBar ? "fixed-top px-0": "fixed-top px-0 shadow"}
        data-aos="fade-up"
        fluid
      >
        {hasTopBar &&
          <Row className="top-bar gx-0 align-items-center d-none d-lg-flex">
            <Col lg={6} className="px-5 text-start">
              <StyledIcon>
                <small>
                  {" "}
                  <RoomIcon /> {address}{" "}
                </small>
              </StyledIcon>
              <StyledIcon>
                <small>
                  {" "}
                  <AccessTimeIcon /> {openingHours}{" "}
                </small>
              </StyledIcon>
            </Col>
            <Col lg={6} className="px-5 text-end">
              <StyledIcon>
                <small>
                  {" "}
                  <EmailIcon /> {email}{" "}
                </small>
              </StyledIcon>
              <StyledIcon>
                <small>
                  {" "}
                  <PhoneIcon /> {phone}{" "}
                </small>
              </StyledIcon>
            </Col>
          </Row>
        }

        <Navbar
          data-aos="fade-up"
          expand="lg"
          className="bg-white py-lg-0 px-lg-5"
        >
          <Navbar.Brand href="" className="navbar-brand ms-4 ms-lg-0">
            <h1 style={{ color: theme.palette.primary.main  }} className="m-0">{brandName}</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
          <Navbar.Collapse
            className="collapse navbar-collapse"
            id="navbarCollapse"
          >
            <Nav defaultActiveKey="/" className="navbar-nav ms-auto p-4 p-lg-0">
              <StyledNavItem as={Link} to="/" className="nav-link">
                Home
              </StyledNavItem>
              <StyledNavItem as={Link} to="/about" className="nav-link">
                About
              </StyledNavItem>
              <StyledNavItem as={Link} to="/services" className="nav-link">
                Services
              </StyledNavItem>
              {/*
                    <Dropdown className="nav-item dropdown">
                        <Dropdown.Toggle as={Link} className="nav-link" data-bs-toggle="dropdown" />
                        <Dropdown.Menu className="dropdown-menu border-light m-0">
                            <Dropdown.Item href="project.html" className="dropdown-item">Projects</Dropdown.Item>
                            <Dropdown.Item href="feature.html" className="dropdown-item">Features</Dropdown.Item>
                            <Dropdown.Item href="team.html" className="dropdown-item">Team Member</Dropdown.Item>
                            <Dropdown.Item href="testimonial.html" className="dropdown-item">Testimonial</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    */}
              <StyledNavItem as={Link} to="/contact" className="nav-link">
                Contact
              </StyledNavItem>
            </Nav>
            <div className="d-none d-lg-flex ms-2">
              <StyledButton href="" variant="light">
                <LinkedInIcon fontSize="small" style={{ color: theme.palette.primary.main }} />{" "}
                {/* text-primary equivalent */}
              </StyledButton>
              <StyledButton href="" variant="light">
                <TwitterIcon fontSize="small" style={{ color: theme.palette.primary.main }} />{" "}
                {/* text-primary equivalent */}
              </StyledButton>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
  return content;
};

export default CustomNavBar;
