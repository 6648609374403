// Bootstrap components
import { Button } from "react-bootstrap";

// Material UI components
import { styled, Theme } from "@mui/material/styles";


export const SubmitMessageButton = styled<Theme>(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: `${theme.palette.common.white} !important`,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `2px ${theme.palette.primary.light}`,
  },
}));

