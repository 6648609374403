import React, { useState, useRef } from "react";

// Email JS
import emailjs from '@emailjs/browser';

// Bootstrap components
import { Col, Row, Form, FloatingLabel } from "react-bootstrap";

// Custom components
import { SubmitMessageButton } from '../common/Buttons';


export const ContactUsForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccess] = useState('');

  const form = useRef();

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

	const emailParams = {
      "full_name": event.currentTarget.fullNameID.value,
      "email": event.currentTarget.emailID.value,
      "tel": event.currentTarget.telID.value,
      "subject": event.currentTarget.subjectID.value,
      "message": event.currentTarget.messageID.value,
	}

    emailjs
      .send('service_hdxxqgj', 'template_jr5zae6', emailParams, {
        publicKey: '1PeCHM-mvCer9f11d',
      })
      .then(
        () => {
          setSuccess("Your email is sent sucessfully");
        },
        (error) => {
          setError("Please retry to send your email");
        },
      );
  };

  const content = (
    <Form onSubmit={handleSubmit} ref={form} className="d-flex align-items-center flex-column justify-content-center">
      <Row className="w-100 p-0 mb-1">
        <Col md={6} className="p-2">
          <Form.Group controlId="formName">
            <FloatingLabel
              controlId="fullNameID"
              label="Full name"
              className="mb-3"
            >
              <Form.Control
                type="text" required placeholder=" "
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        
        <Col md={6} className="p-2">
          <Form.Group controlId="formEmail">
            <FloatingLabel
              controlId="emailID"
              label="Email"
              className="mb-3"
            >
              <Form.Control 
                type="email" required placeholder=" "
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      
      <Row className="w-100 p-0 mb-2">
        <Col md={6} className="p-2">
          <Form.Group controlId="formPhone" className="">
            <FloatingLabel
              controlId="telID"
              label="Phone number"
              className="mb-3"
            >
              <Form.Control type="tel" required placeholder=" " 
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        
        <Col md={6} className="p-2">
          <Form.Group controlId="formSubject">
            <FloatingLabel
              controlId="subjectID"
              label="Subject"
              className="mb-3"
            >
              <Form.Control type="text" required placeholder=" " 
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      <Row className="w-100 p-0">
        <Col md={12} className="p-2">
          <Form.Group controlId="formMessage" className="w-100">
            <FloatingLabel
              controlId="messageID"
              label="Message"
              className="mb-1"
            >
              <Form.Control as="textarea" style={{ height: '200px' }} required placeholder=" " 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
    </Row>
    <Row>
      <SubmitMessageButton type="submit" className="mt-2">
        Send Message
      </SubmitMessageButton>
    </Row>
  </Form>
  );
  return content;
};
