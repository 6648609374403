import React from "react";

// Custom forms
import {
  ContactUsForm
} from "../components/forms/ContactForm";


const ContactPage = () => {
  const content = (
    <>
      <div className="container-xxl py-5 mt-4">
        <div className="container">
          <div className="row g-5">
            <div data-aos="zoom-in" className="col-lg-6" data-wow-delay="0.1s">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                Contact
              </p>
              <h1 className="display-5 mb-4">
                For Any Questions, Please Reach Out to Us
              </h1>
              <p className="mb-4">
                {`We’re here to help! Reach out with any questions or feedback, and our team will assist you promptly.`}
              </p>
              <ContactUsForm />
            </div>
            <div
              className="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              style={{ minHeight: "450px" }}
            >
              <div className="position-relative rounded overflow-hidden h-100">
                <iframe
                  className="position-relative w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105984.47349403394!2d35.356627135045706!3d33.889272760425975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut%2C%20Lebanon!5e0!3m2!1sen!2sus!4v1723579124661!5m2!1sen!2sus"
                  frameBorder="0"
                  style={{ minHeight: "450px", border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return content;
};

export default ContactPage;
