import { styled } from "@mui/material/styles";

// Bootstrap component
import { Button } from "react-bootstrap";


export const StyledIcon = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  color: theme.palette.primary.main, // equivalent to text-primary
  marginRight: theme.spacing(2), // equivalent to me-2 (margin-end 2)
}));


export const FooterIcon = styled("p")(({ theme }) => ({
  display: "inline-flex",
  color: theme.palette.common.white,
  "> svg": {
    marginRight: "5px",
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: "32px", // Adjust size as necessary
  height: "32px", // Adjust size as necessary
  padding: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%", // rounded-circle
  marginLeft: "16px", // ms-3 (margin-start 3)
  backgroundColor: theme.palette.common.white, // btn-light
  borderColor: theme.palette.common.white, // to match btn-light border
  "&:hover": {
    backgroundColor: theme.palette.grey[200], // slightly darker on hover
    borderColor: theme.palette.grey[200], // hover effect for border
  },
}));
