import React from "react";

// Bootstrap components
import { Container, Row } from "react-bootstrap";

import { useTheme } from '@mui/material';

// Custom forms
import {
  ContactUsForm
} from "../forms/ContactForm";


export interface Contact {
  address: string;
  openingHours: string;
  email: string;
  phone: string;
  brandName?: string;
}


const ContactUs = () => {
  const theme = useTheme();
  const content = (
    <Container className="facts my-5 pt-5" fluid>
      <Container className="d-flex align-items-center justify-content-center pt-3">
        <Row className="p-2 rounded d-flex align-items-center justify-content-center bg-white w-50"
          data-aos="zoom-in"
          style={{border: `10px solid ${theme.palette.primary.main}`}}
        >
          <h2 className="w-100 text-center">Get in Touch with SKYLYNCS</h2>
          <p className="w-100 text-center">
            Elevate your business with top tech talent and innovative solutions. 
            For inquiries, support, or partnership opportunities, fill out the form below. Let’s start a transformative conversation today.
          </p>
          <ContactUsForm />
          <p className="mt-3 text-center text-muted">
            We value your privacy. Your information will be kept confidential and used only to respond to your inquiry.
          </p>
        </Row>
      </Container>
    </Container>
  );
  return content;
};

export default ContactUs;
