import React from "react";

// Bootstrap components
import { Card, Image, Col, Row, Container } from "react-bootstrap";

// Custom components
import {
  VisionIcon,
  MissionIcon
} from '../common/Icons';

import { CardTitle } from '../common/Cards';


export interface Brand {
  name: string;
  description: string;
  story: string;
  vision: string;
  mission: string;
  slogan: string;
}


const AboutUs: React.FC<Brand> = ({
  name,
  description,
  story,
  vision,
  mission,
  slogan,
}) => {
  const content = (
    <Container expand="xxl" className="mt-4 py-5">
      <Container>
        <Row className="g-4 p-2 align-items-end mb-4">
          <Col data-aos="fade-right" lg={6}>
            <Image 
              fluid 
              src="https://img-s-msn-com.akamaized.net/tenant/amp/entityid/AA1jVWRu.img"
            />
          </Col>
          <Col data-aos="fade-right" className="d-flex flex-column align-items-stretch" lg={6}>
            <h1 className="display-5 mb-4">{slogan}</h1>
            <p className="mb-4">{description}</p>
          </Col>
        </Row>
        <Row className="h-25 g-4 p-2 d-flex align-items-center justify-content-center">
          <Col md={7} lg={6}>
            <Card className="p-2 d-flex align-items-center jusitfy-content-center">
              <VisionIcon />
              <CardTitle>Our Vision</CardTitle>
              <Card.Body>
                <Card.Text>
                  {vision}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={6}>
            <Card className="p-2 d-flex align-items-center jusitfy-content-center">
              <MissionIcon />
              <CardTitle>Our Mission</CardTitle>
              <Card.Body>
                <Card.Text>
                  {mission}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
  return content;
};

export default AboutUs;
