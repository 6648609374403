import React from "react";

// Bootstrap components
import { Container, Col, Row, Nav, Tab, Image } from "react-bootstrap";

// Material UI components
import MenuIcon from "@mui/icons-material/Menu";

// Custom components
import { CustomNav, NavItem } from '../common/Navigations';
import { CheckTickIcon } from '../common/Icons';

// Images
import ServiceImage from "../../assets/img/services.jpg";
import ProductImage from "../../assets/img/products.jpg";
import ResourcesImage from "../../assets/img/resources.jpeg";


const Services = () => {
  const content = (
    <Container expand="xxl" className="py-5">
      <Container>
        <div
          data-aos="fade-up"
          className="text-center mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h1 className="mb-5"> Resources & Services </h1>
        </div>
        <Tab.Container
          defaultActiveKey="resources"
        >
          <Row className="g-4"
            data-aos="zoom-in-up"
          >
            <Col lg={4}>
              <CustomNav
                variant="pills"
                className="flex-column p-2 d-flex w-100 h-100 me-4"
              >
                <NavItem>
                  <Nav.Link eventKey="resources">
                    <MenuIcon className="me-3"/>
                    Resources
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link eventKey="services">
                    <MenuIcon className="me-3"/>
                    Services
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link eventKey="products">
                    <MenuIcon className="me-3"/>
                    Products
                  </Nav.Link>
                </NavItem>

                <NavItem>
                  <Nav.Link eventKey="solutions">
                    <MenuIcon className="me-3"/>
                    Solutions
                  </Nav.Link>
                </NavItem>
              </CustomNav>
            </Col>

            <Col lg={8}>
              <Tab.Content className="tab-content w-100">
                {/*Resources*/}
                <Tab.Pane className="fade show" eventKey="resources">
                  <Row className="g-4">
                    <Col md={6} style={{ minHeight: "350px" }}>
                      <div className="position-relative h-100">
                        <Image
                          className="position-absolute rounded w-100 h-100"
                          src={ResourcesImage}
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <h3 className="mb-4">
                        More then 25 years experience in the Telecomunication
                        industry
                      </h3>
                      <p className="mb-4">
                        As a leading recruitment firm, we connect exceptional tech talent with innovative companies,
                        ensuring that our clients have access to the best minds in the industry. 
                        Our aim is to provide tailored engineering solutions that meet even the most demanding client needs.
                      </p>
                      <p>
                        <CheckTickIcon />
                        Design and optimization
                      </p>
                      <p>
                        <CheckTickIcon />
                        Technical support
                      </p>
                      <p>
                        <CheckTickIcon />
                        Network Audit
                      </p>
                      <p>
                        <CheckTickIcon />
                        Core and E2E
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>
                {/*Services*/}
                <Tab.Pane eventKey="services">
                  <Row className="g-4">
                    <Col md={6} style={{ minHeight: "350px" }}>
                      <div className="position-relative h-100">
                        <Image
                          className="position-absolute rounded w-100 h-100"
                          src={ServiceImage}
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <h3 className="mb-4">
                        Service Excellence
                      </h3>
                      <p className="mb-4">
                        Beyond talent acquisition, our comprehensive suite of technology services empowers organizations to thrive in a competitive landscape.
                        We understand the intricacies of the telecom sector, and our services are designed to address the unique challenges our clients face.
                      </p>
                      <p>
                        <CheckTickIcon />
                        Design and optimization
                      </p>
                      <p>
                        <CheckTickIcon />
                        Technical support specialists
                      </p>
                      <p>
                        <CheckTickIcon />
                        Innovative technology deployment & dismantling
                      </p>
                      <p>
                        <CheckTickIcon />
                        Business cases and implementation
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>
                {/*Products*/}
                <Tab.Pane eventKey="products">
                  <Row className="g-4">
                    <Col md={6} style={{ minHeight: "350px" }}>
                      <div className="position-relative h-100">
                        <Image
                          className="position-absolute rounded w-100 h-100"
                          src={ProductImage}
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <h3 className="mb-4">
                        Products providers
                      </h3>
                      <p className="mb-4">
                        Beyond talent acquisition, our comprehensive suite of technology services empowers organizations to thrive in a competitive landscape.
                        We understand the intricacies of the telecom sector, and our services are designed to address the unique challenges our clients face.
                      </p>
                      <p>
                        <CheckTickIcon />
                        Antenna, Radios & Jammers
                      </p>
                      <p>
                        <CheckTickIcon />
                        ORAN products
                      </p>
                      <p>
                        <CheckTickIcon />
                        Software products: CRM, Cloud & ERP
                      </p>
                      <p>
                        <CheckTickIcon />
                        IT and Networking solutions
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>
                {/*Solutions*/}
                <Tab.Pane eventKey="solutions">
                  <Row className="g-4">
                    <Col md={6} style={{ minHeight: "350px" }}>
                      <div className="position-relative h-100">
                        <Image
                          className="position-absolute rounded w-100 h-100"
                          src="img/service-1.jpg"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <h3 className="mb-4">
                        Solutions
                      </h3>
                      <p className="mb-4">
                        Beyond talent acquisition, our comprehensive suite of technology services empowers organizations to thrive in a competitive landscape.
                        We understand the intricacies of the telecom sector, and our services are designed to address the unique challenges our clients face.
                      </p>
                      <p>
                        <CheckTickIcon />
                        Green energy and Hybrid solutions
                      </p>
                      <p>
                        <CheckTickIcon />
                        Energy storage
                      </p>
                      <p>
                        <CheckTickIcon />
                        IOT devices
                      </p>
                      <p>
                        <CheckTickIcon />
                        Automated Quality Assurance
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </Container>
  );
  return content;
};

export default Services;
