import React from "react";

// Custom components
import ChoosingUs from "../components/global/ChoosingUs";
import Services from "../components/global/Services";
import ContactUs from "../components/global/ContactUs";

// Data
import BrandData from "../assets/data/brand.json";

const ServicesPage = () => {
  const content = (
    <>
      <ChoosingUs
        title={BrandData.choosingUs.title}
        description={BrandData.choosingUs.description}
        generalServices={BrandData.generalServices}
        generalResources={BrandData.generalResources}
      />
      <Services />
      <ContactUs />
    </>
  );
  return content;
};

export default ServicesPage;
