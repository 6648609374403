import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// Animation
import AOS from "aos";

// Custom components
import CustomNavBar from "./NavBar";
import Footer from "./Footer";

// Load data
import ContactData from "../../assets/data/contact.json";
import BrandData from "../../assets/data/brand.json";

const Layout = () => {

  const [hasTopBar, setTopBar] = useState(true);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
      once: false
    });

    const handleScroll = (event) => {
      if (window.scrollY > 0) {
        setTopBar(false);
      } else {
        setTopBar(true);
      };
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const content = (
    <>
      <CustomNavBar
        brandName={BrandData.name}
        address={ContactData.address}
        openingHours={ContactData.openingHours}
        email={ContactData.email}
        phone={ContactData.phone}
        hasTopBar={hasTopBar}
      />
      <Outlet />
      <Footer 
        brand={BrandData}
        contact={ContactData}
      />
    </>
  );

  return content;
};

export default Layout;
