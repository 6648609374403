// Material UI components
import { styled, Theme } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from "@mui/icons-material/Check";
import FlagIcon from '@mui/icons-material/Flag';


/* General Icons */
export const VisionIcon = styled(VisibilityIcon)<Theme>(({ theme }) => ({
  fontSize: "3rem",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
  backgroundColor: "transparent !imporant",
}));


export const MissionIcon = styled(FlagIcon)<Theme>(({ theme }) => ({
  fontSize: "3rem",
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
  backgroundColor: "transparent !imporant",
}));


export const CheckTickIcon = styled(CheckIcon)<Theme>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(3),
  backgroundColor: "transparent !important",
}));


export const ReadMoreIcon = styled(ArrowForwardIcon)<Theme>(({ theme }) => ({
  marginLeft: '0.25rem', // ms-1
  color: theme.palette.primary.main,
  '&:hover': {
    color: `${theme.palette.common.white} !important`
  },
}));
