// Bootstrap components
import { Card } from "react-bootstrap";

// Material UI components
import { styled, Theme } from "@mui/material/styles";


export const CardTitle = styled<Theme>(Card.Text)(({ theme }) => ({
  fontSize: "2rem", // equivalent to fa-3x
  fontWeight: "bold",
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(3), // equivalent to mb-3
  backgroundColor: "transparent !imporant",
}));

