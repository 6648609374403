import React from "react";

// Custom components
import AboutUs from "../components/global/AboutUs";
import SuccessStory from "../components/global/SuccessStory";
import ChoosingUs from "../components/global/ChoosingUs";
import Services from "../components/global/Services";
import ContactUs from "../components/global/ContactUs";

// Data
import BrandData from "../assets/data/brand.json";


const LandingPage = () => {
  const content = (
    <>
      <AboutUs
        name={BrandData.name}
        description={BrandData.description}
        story={BrandData.story}
        vision={BrandData.vision}
        mission={BrandData.mission}
        slogan={BrandData.slogan}
      />
      <SuccessStory />
      <ChoosingUs
        title={BrandData.choosingUs.title}
        description={BrandData.choosingUs.description}
        generalServices={BrandData.generalServices}
        generalResources={BrandData.generalResources}
      />
      <Services />
      <ContactUs />
    </>
  );
  return content;
};

export default LandingPage;
