import { styled } from "@mui/material/styles";

// Bootstrap component
import { Nav, Tab } from "react-bootstrap";


export const StyledNavItem = styled(Nav.Item)(({ theme }) => ({
  color: theme.palette.grey[500],
  "&:hover": {
    color: theme.palette.primary.light,
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
}));


export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[500],
  "&:hover": {
    color: theme.palette.primary.light,
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
}));


