import React from "react";

// Bootstrap components
import {
  Container,
  Row,
  Col
} from "react-bootstrap";

// Custom components
import { Box } from "../common/Box";


export interface Meta {
  title: string;
  summary: string;

};


export interface ChoosingUsProps {
  title: string;
  description: string;
  generalServices?: Meta[];
  generalResources?: Meta[];
}


const ChoosingUs: React.FC<ChoosingUsProps> = ({ 
  title, 
  description, 
  generalServices, 
  generalResources
}) => {
  const content = (
    <Container expand="xxl" className="bg-white py-5 mt-5">
      <Container className="p-2">
        <Row className="g-5 align-items-center">
          <Col lg={6} data-aos="fade-up">
            <h1 className="display-5 mb-4">{title}</h1>
            <p className="mb-4">{description}</p>
          </Col>
          <Col lg={6}>
            <Row className="g-4 align-items-center">
              <Col 
                className="col-md-6" 
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                <Row className="g-4">
                  {generalServices.length > 0 && generalServices.map((service, idx) => {
                    return (
                    <Col data-aos="zoom-in" className="col-12">
                      <Box className="p-4">
                        {/*<StyledBuildIcon /> */}
                        <h4 className="mb-3">
                          {service.title}
                        </h4>
                        <p className="mb-3">
                          {service.summary}
                        </p>
                        {/*
                        <ReadMoreLink href="">
                          Read More <ReadMoreIcon />
                          </ReadMoreLink>*/}
                      </Box> 
                    </Col>
                    )
                  }
                  )}
                </Row>
              </Col>
              <Col className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <Row className="g-4">
                  {generalResources.length > 0 && generalResources.map((resource, idx) => {
                    return (
                    <Col data-aos="zoom-in-down" className="col-12">
                      <Box className="feature-box border rounded p-4">
                        {/*<StyledLibraryBooksIcon /> */}
                        <h4 className="mb-3">
                          {resource.title}
                        </h4>
                        <p className="mb-3">
                          {resource.summary}
                        </p>
                        {/*
                        <ReadMoreLink href="">
                          Read More <ReadMoreIcon />
                          </ReadMoreLink>*/}
                      </Box> 
                    </Col>
                    )
                  }
                  )}
                </Row>
              </Col>
          </Row>
        </Col>
        </Row>
      </Container>
    </Container>
  );
  return content;
};

export default ChoosingUs;
