import React from "react";
import ReactDOM from "react-dom/client";

// CSS styling
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/index.css";

// Material UI
import { ThemeProvider } from '@mui/material';

// Animation
import "aos/dist/aos.css";

// App router
import AppRouter from "./pages/App";

// Custom Themes
import CustomTheme from "./components/layout/Theme";

// React router
import { RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={CustomTheme}>
      <RouterProvider router={AppRouter} />
    </ThemeProvider>
  </React.StrictMode>
);
