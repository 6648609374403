// Bootstrap component
import { Nav } from "react-bootstrap";

// Material UI icons
import { styled, Theme } from "@mui/material/styles";


export const CustomNav = styled(Nav)<Theme>(({ theme }) => ({
  backgroundColor: "transparent",
  ".nav-link.active": {
    backgroundColor: theme.palette.primary.main,
  }
}));


export const NavItem = styled(Nav.Item)<Theme>(({ theme }) => ({
  border: "none",
  borderRadius: "5%",
  transition: 'background-color 0.3s, color 0.3s',
  marginBottom: "15px",
  padding: "0px",
  "> a": {
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: "5%",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "2px",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    "> svg" : {
      fontSize: "1.5rem"
    }
  },
}));

