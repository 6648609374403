import React from "react";

// Custom components
import AboutUs from "../components/global/AboutUs";
import SuccessStory from "../components/global/SuccessStory";

// Data
import BrandData from "../assets/data/brand.json";

const AboutPage = () => {
  const content = (
    <>
      <AboutUs
        name={BrandData.name}
        description={BrandData.description}
        story={BrandData.story}
        vision={BrandData.vision}
        mission={BrandData.mission}
        slogan={BrandData.slogan}
      />
      <SuccessStory />
    </>
  );
  return content;
};

export default AboutPage;
