import React from "react";

// Bootstrap component
import { Container, Col, Row } from "react-bootstrap";

// React router dom 
import { Link } from "react-router-dom";

// Material ui components
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { styled } from "@mui/material/styles";

// Custom types
import { Brand } from '../global/AboutUs';
import { Contact } from '../global/ContactUs';

// Custom components
import { StyledButton, FooterIcon } from '../icons/StyledClicks';


export interface FooterProps {
  contact?: Contact;
  brand?: Brand;
};


export const FooterContainer = styled(Container)(({ theme }) => ({
  display: "block",
  marginBottom: 0,
  padding: 0,
  textAlign: "left",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  fontWeight: "normal",
  textTransform: "capitalize",
  transition: "0.3s",
  "> a": {


  }
}));

export const CopyRightContanier = styled(Container)(({ theme }) => ({
  display: "block",
  marginBottom: 0,
  padding: 0,
  textAlign: "left",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  fontWeight: "normal",
  textTransform: "capitalize",
  transition: "0.3s",
}));


export const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: "none",
  padding: "2px",
}));


const Footer: React.FC<FooterProps> = ({
  contact,
  brand
}) => {
  const content = (
    <>
      <FooterContainer
        className="facts-2 text-light mt-5 py-5"
        data-aos="fade-up"
        fluid
      >
        <Container className="py-5">
          <Row className="g-5">
            <Col lg={6} md={6}>
              <h4 className="text-white mb-4">Our Office</h4>
              <div className="d-flex flex-column pt-2 mb-2">
                <FooterIcon>
                  <RoomIcon /> {contact.address}
                </FooterIcon>
                <FooterIcon>
                  <PhoneIcon /> {contact.phone}
                </FooterIcon>
                <FooterIcon>
                  <EmailIcon /> {contact.email}
                </FooterIcon>
              </div>
              <div className="d-flex pt-2">
                <StyledButton href={brand.accounts.linkedin} variant="light">
                  <LinkedInIcon fontSize="small" style={{ color: "#555555" }} />{" "}
                  {/* text-primary equivalent */}
                </StyledButton>
                <StyledButton href={brand.accounts.twitter} variant="light">
                  <TwitterIcon fontSize="small" style={{ color: "#555555" }} />{" "}
                  {/* text-primary equivalent */}
                </StyledButton>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <h4 className="text-white text-left mb-4">Quick Links</h4>
              <div className="d-flex flex-column pt-2 mb-2">
                <FooterLink to="/about">
                  About Us
                </FooterLink>
                <FooterLink to="/contact">
                  Contact Us
                </FooterLink>
                <FooterLink to="/services">
                  Our services
                </FooterLink>
              </div>
            </Col>
            {/*
            <Col lg={3} className="col-md-6">
              <h4 className="text-white mb-4">Newsletter</h4>
              <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
              <div className="position-relative w-100">
                <input
                  className="form-control bg-white border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  Contact Us
                </button>
              </div>
            </Col>
              */}
          </Row>
        </Container>
      </FooterContainer>

      <Container fluid className="copyright py-4">
        <Container className="container">
          <Row>
            <Col className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <FooterLink to="#">
                Skylyncs.net
              </FooterLink>
              , All Right Reserved.
            </Col>
            <Col className="col-md-6 text-center text-md-end">
              Designed By{" "}
              <FooterLink href="https://dikode.ai">
                Dikode.ai
              </FooterLink>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
  return content;
};

export default Footer;
