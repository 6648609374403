import { createTheme } from '@mui/material/styles';

const CustomTheme = createTheme({
  palette: {
    primary: {
      light: '#3358a4',
      main: '#002f8e',
      dark: '#002063',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e73333',
      main: '#e20000',
      dark: '#9e0000',
      contrastText: '#000',
    },
  },
});

export default CustomTheme;
