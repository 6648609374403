// Material UI components
import { styled, Theme } from "@mui/material/styles";

export const Box = styled<Theme>('div')(({ theme }) => ({
  transition: '0.5s',
  backgroundColor: theme.palette.common.white,
  border: "2px solid",
  borderRadius: "2%",
  borderColor: `${theme.palette.primary.light} !important`,
  '> svg': {
    color: theme.palette.primary.main,
    backgroundColor: "transparent !imporant",
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.secondary.light,
    color: `${theme.palette.common.white} !important`
  },
  '&:hover > svg a': {
    color: "#fff",
    backgroundColor: "transparent !imporant",
  }
}));
