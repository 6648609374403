import React from "react";

// Bootstrap components
import { Container, Row, Col, Image } from "react-bootstrap";

// Material UI components
import { useTheme } from '@mui/material';

// Trusted partners logo
import TouchLogo from "../../assets/img/touch_logo.png";
import MtnLogo from "../../assets/img/mtn_logo.png";
import AlJohiLogo from "../../assets/img/aljohi_logo.png";
import iENGLogo from "../../assets/img/ieng_logo.png";


const SuccessStory = () => {
  const theme = useTheme();

  const content = (
    <>
      <Row data-aos="fade-down" className="d-flex justify-content-center align-items-center">
        <h2 style={{color: theme.palette.primary.main }} className="text-center"> 
          Global Partners Who Trust in Our Expertise
        </h2>
      </Row>
      <Container className="facts my-5 py-5" fluid>
        <Container className="py-5">
          <Row className="g-5 align-items-center justify-content-center">
            <Col
              sm={6}
              lg={3}
              className="text-center"
              data-aos="fade-left" 
            >
              <Image src={TouchLogo} roundedCircle />
            </Col>
            <Col
              sm={6}
              lg={3}
              className="text-center"
              data-aos="fade-left" 
            >
              <Image src={MtnLogo} roundedCircle />
            </Col>
            <Col
              sm={6}
              lg={3}
              className="text-center"
              data-aos="fade-right" 
            >
              <Image src={AlJohiLogo} />
            </Col>
            <Col
              sm={6}
              lg={3}
              className="text-center"
              data-aos="fade-right" 
            >
              <Image src={iENGLogo}/>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
  return content;
};

export default SuccessStory;
